import React, { Component } from "react"
import Layout from "../components/layout"
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap"
import mail from "../images/mail.svg"
import padlockClosed from "../images/padlock-closed.svg"
import Input, { isValidPhoneNumber } from 'react-phone-number-input/input'
import axios from "axios"
import {
  ALERT_MSG_ERROR,
  AUTH_TOKEN_KEY, FEMALE,
  firstLetterCapitalize, FR, KEY_EMAIL, KEY_LANGUAGE_BROWSER, KEY_LANGUAGE_USER, MAN, NL, PARAM_CARD_CORECT_NOT_FOUND,
  PARAM_CARD_NMBRE, PARAM_FIRST_NAME, translate,
  URL_GETONE_BCEDATA_BYNCRDNUMBER,URL_SEND_MAIL_USER,
  URL_LOGIN, URL_REGISTER_USER
} from "../utils"
import ModalAlert from "../components/modalAlert"

class SignupThree extends Component {
  state = {
    prenom_1: '',
    nom_1: '',
    sexe_1: MAN,
    email: '',
    numero_de_telephone: '',
    ncrd_num: '',
    setAtMale: null,
    setAtFemale: null,
    langue_operationnelle: null,
    showAlert: false,
    alertTitle: '',
    alertMessage: '',
    placeholderFirstName:'',
    placeholderLastName:'',
    placeholderPhone:'',
    btnIsActive: false,
  }

  componentDidMount = async () => {
    this.setState({
      placeholderFirstName:translate('voornaam')+'*',
      placeholderLastName:translate('naam')+'*',
      placeholderPhone:translate('mobile')+"*",
    })
    await this.getDataFromBce();
    this.getUserLanguage();
  }

  getUserLanguage = () => {
    const {langue_operationnelle} = this.state;
    if ((langue_operationnelle !== NL) && (langue_operationnelle !== FR)) {
      let language = NL;
      const usrLanguage = localStorage.getItem(KEY_LANGUAGE_USER);
      const browsrLanguage = localStorage.getItem(KEY_LANGUAGE_BROWSER);
      if (usrLanguage) {
        language = usrLanguage
      } else if (browsrLanguage) language = browsrLanguage;
      this.setState({ langue_operationnelle: language });
    }
  }

  getDataFromBce = async () => {
    if (this.props.location) {
      const query = new URLSearchParams(this.props.location.search);
      if (query.get(PARAM_CARD_NMBRE) !== null) {
        await this.setState({ncrd_num: query.get(PARAM_CARD_NMBRE)});
        const data = {
          ncrd_num: this.state.ncrd_num,
        };
        axios.post(URL_GETONE_BCEDATA_BYNCRDNUMBER,data)
          .then(res => {
            if (res.data) {
              const {
                nom_de_commerce,
                description_forme_juridique,
                numero_d_enterprise,
                code_secteur,
                description_de_code_secteur,
                code_d_activite,
                description_d_activite,
                establishmentDate,
                rue,
                numero_de_maison_ajout,
                code_postal,
                lieu,
                nom_1,
                sexe_1,
                numero_de_telephone,
                langue_operationnelle,
                prenom_1, 
              } = res.data;

              this.setState({
                haveNcrdNumNotOnBCE: false,
                nom_de_commerce,
                description_forme_juridique,
                numero_d_enterprise,
                code_secteur,
                description_de_code_secteur,
                code_d_activite,
                description_d_activite,
                establishmentDate: '03/29/2020',
                rue,
                numero_de_maison_ajout,
                code_postal,
                lieu: firstLetterCapitalize(lieu),
                nom_1: firstLetterCapitalize(nom_1),
                sexe_1,
                numero_de_telephone: '',
                langue_operationnelle,
                prenom_1: firstLetterCapitalize(prenom_1),
                country: 'Belgium',
                validBtColor:true,
              })
              if (sexe_1 === FEMALE) {
                this.setState({sexe_1: FEMALE,setAtMale:false,setAtFemale:true})
              }else if (sexe_1 === MAN) this.setState({sexe_1: MAN,setAtMale:true,setAtFemale:false})
            }
          })
          .catch(err => {
            if (err.response) {
              console.log(err.response.data);
            }
            console.log(err)
          });
      }
    }
  }

  registerUser = async (e) => {
    e.preventDefault();
    const {numero_de_telephone,email,langue_operationnelle} = this.state

    if ((numero_de_telephone !== '') && (email !== '')) {
      axios.post(URL_REGISTER_USER,this.state)
        .then(res => {
          if (res.data) {   
             this.redirectToNextPage()
          }
        })
        .catch(err => {
          if (err.response && err.response.data) {
            console.log(err.response.data);
            this.setState({
              showAlert: true,
              alertTitle: ALERT_MSG_ERROR,
            })

            if (err.response.data.message === 'registered but no account') {
              this.setState({alertMessage: translate('vous avez arrêter votre inscription en cours de procédure, vous allez être rediriger vers l\'étapte suivante')})
              setTimeout(this.redirectToNextPage,2000)
            }else {
              if (Array.isArray(err.response.data.message)) {
                if (err.response.data.message[0] === 'email must be an email') {
                  this.setState({alertMessage: translate('email must be an email')})
                }else if (err.response.data.message[0] === 'numero_de_telephone must be a valid phone number') {
                  this.setState({alertMessage: translate('numero_de_telephone must be a valid phone number')})
                }else if (err.response.data.message === 'registered but no account') {
                  this.setState({alertMessage: translate('vous avez arrêter votre inscription en cours de procédure, vous allez être rediriger vers l\'étapte suivante')})
                }else this.setState({alertMessage: err.response.data.message})
              }else this.setState({alertMessage: err.response.data.message})
            }
          }
          console.log(err)
        });
    }else {
      this.setState({
        showAlert: true,
        alertTitle: ALERT_MSG_ERROR,
        alertMessage: translate("het telefoonnummer van de telefoon en het e-mailadres is vereist"),
      })
    }
  }

  redirectToNextPage = () => {
    localStorage.setItem(KEY_EMAIL,this.state.email);
    const query = new URLSearchParams(this.props.location.search);

    if (query.get(PARAM_CARD_CORECT_NOT_FOUND) !== null) {
      if (query.get(PARAM_CARD_CORECT_NOT_FOUND) === 'true') {
        window.location.href = `/company-exist?${PARAM_CARD_NMBRE}=${this.state.ncrd_num}&${PARAM_CARD_CORECT_NOT_FOUND}=${true}&${PARAM_FIRST_NAME}=${this.state.prenom_1}`
      }else {
        window.location.href = `/user-check-info-two?${PARAM_CARD_NMBRE}=${this.state.ncrd_num}`
      }


    }else if (query.get(PARAM_CARD_NMBRE)) {
      if (query.get(PARAM_CARD_NMBRE) === 'false') {
        window.location.href = `/company-exist?${PARAM_CARD_NMBRE}=${this.state.ncrd_num}&${PARAM_FIRST_NAME}=${this.state.prenom_1}`
      }else {
        window.location.href = `/user-check-info-two?${PARAM_CARD_NMBRE}=${this.state.ncrd_num}`
      }
    }
  }
 
  onChangeSex = (sex) => {
    if (sex === FEMALE) {
      this.setState({sexe_1: FEMALE,setAtMale:false,setAtFemale:true})
    }else if (sex === MAN) this.setState({sexe_1: MAN,setAtMale:true,setAtFemale:false})
  }

  verificationForm = () => {
    if(this.state.numero_de_telephone && this.state.numero_de_telephone.length !== 0) {
      return !!isValidPhoneNumber(this.state.numero_de_telephone);
    }
  }

  render() {
    const {setAtMale,setAtFemale,showAlert,alertMessage,alertTitle,
      placeholderFirstName,
      placeholderLastName,
      placeholderPhone,btnIsActive,email,prenom_1,nom_1,numero_de_telephone
    } = this.state

    return (
      <Layout withBg>
        <div className="formWrapper userCheckInfo f12">
          <Container>
            <Row className="justify-content-center">
              <Col md="6" lg="6" xl="6">
                <div className="text-left">
                  <h2>
                  {translate('welkom')} 
                      <span className="mb-4"> {translate('op enter-business',false)}</span>
                  </h2>
                  <p>{translate('Pour pouvoir recevoir vos avantages vous devez bien entendu vous identifier. Sans cela et sans savoir qui vous êtes, comment pourrions-nous vous les offrir ?')}😉</p>
                  <Form onSubmit={(e) => this.registerUser(e)}>
                    <Form.Row className="align-items-center">
                        <Col lg="5">
                          <Form.Group className="mb-3">
                            <FormControl
                              value={this.state.nom_1}
                              onChange={(e) => this.setState({nom_1: e.target.value})}
                              placeholder={placeholderLastName}
                              aria-describedby="basic-addon1"
                            />
                          </Form.Group>
                        </Col>
                        <Col lg="5">
                          <Form.Group className="mb-3">
                            <FormControl
                              value={this.state.prenom_1}
                              onChange={(e) => this.setState({prenom_1: e.target.value})}
                              placeholder={placeholderFirstName}
                              aria-describedby="basic-addon1"
                            />
                          </Form.Group>
                        </Col>
                        <Col lg="2">
                          <Form.Group className="mb-3">
                            <Form.Check checked={setAtMale} onClick={() => this.onChangeSex(MAN)} inline label={translate("man")} type="radio" name="gender" id="checkbox" />
                            <Form.Check checked={setAtFemale} onClick={() => this.onChangeSex(FEMALE)} inline label={translate("vrouw")} type="radio" name="gender" id="checkbox" />
                          </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row className="align-items-center">
                      <Col lg="7">
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              <img
                                width={16}
                                height={8}
                                className="img-fluid"
                                src={mail}
                                alt=""
                              />
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            value={this.state.email} 
                            onChange={(e) => this.setState({email: e.target.value.replace('é','e').replace('è','e').toLowerCase()})}
                            placeholder="E-mail*"
                            aria-label=""
                            aria-describedby="basic-addon1"
                          />
                        </InputGroup>
                      </Col>
                      <Col lg="5">
                        <Form.Group className="mb-3">
                          {/* <FormControl
                              value={this.state.numero_de_telephone}
                              onChange={(e) => this.setState({numero_de_telephone: e.target.value})}
                              placeholder={placeholderPhone}
                              aria-describedby="basic-addon1"
                            /> */}
                          <Input
                            className="userCheckInfo form-group form-control"
                            style={{borderColor: this.verificationForm() === false && 'red' }}
                            // initialValueFormat="national"
                            defaultCountry="BE"
                            placeholder={placeholderPhone}
                            value={this.state.numero_de_telephone}
                            onChange={(value) => {
                              this.setState({numero_de_telephone: value});
                            }
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Form.Row> 

                    <div className="d-md-flex justify-content-center mt-3 form-group row">
                      {(!showAlert)?
                        <Button disabled={(prenom_1 === '') || (nom_1 === '') || (email === '') || (numero_de_telephone === '') || (prenom_1 === undefined) || (nom_1 === undefined) || (email === undefined) || (numero_de_telephone === undefined) || (isValidPhoneNumber(numero_de_telephone) === false)} type="submit" if  variant="success" className="button-yellow">
                          {translate('verdergaan')}
                        </Button>

                        :
                        <Button disabled={(prenom_1 === '') || (nom_1 === '') ||  (email === '') || (numero_de_telephone === '') || (prenom_1 === undefined) || (nom_1 === undefined) || (email === undefined) || (numero_de_telephone === undefined) || (isValidPhoneNumber(numero_de_telephone) === false)} type="submit" if  variant="success" className="button-yellow checkme">
                          {translate('verdergaan')}
                        </Button>
                      }
                    </div>
                    <p class="pt-4">{translate("Soyez rassuré(e), il n’y a pas de pièges !")}<br/>{translate("Si vous n’êtes pas satisfait(e), vous pouvez vous désinscrire facilement à tout moment.")}</p>
                    <p>{translate("Pour rappel, déjà plus de 600 € d’avantages disponibles dès votre inscription")}</p>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {(showAlert)?
          <ModalAlert
            alertTitle={alertTitle}
            alertMessage={alertMessage}
            closeModal={() => this.setState({showAlert: false})}
          />:null
        }
      </Layout>
    )
  }
}

export default SignupThree
